
import { Component, Vue } from "vue-property-decorator";
import {
  lightningChart,
  Themes,
  ColorRGBA,
  ColorHEX,
  SolidFill,
  //   PointShape,
  SolidLine,
  // EventMarkers,
  AxisTickStrategies,
  emptyLine,
  translatePoint,
  ColorCSS,
  customTheme,
  UIElementBuilders,
  UIOrigins,
  //   FunnelChartWithLabelsOnSides,
} from "@arction/lcjs";

@Component
export default class TimeseriesChart extends Vue {
  chart: any = null;

  axisY;

  chartId: any = null;

  eventBars: any[] = [];

  mounted() {
    this.createChart();
  }

  beforeMount() {
    // Generate random ID to us as the containerId for the chart and the target div id
    this.chartId = Math.trunc(Math.random() * 1000000);

    //   console.log(this.chartId);
  }

  createChart() {
    const themeTextFillStyle = new SolidFill({ color: ColorCSS("#ffffff") });
    const themeDataSeriesFillStyles = [
      new SolidFill({ color: ColorCSS("#00FFEA") }),
      new SolidFill({ color: ColorCSS("#F21688") }),
      new SolidFill({ color: ColorCSS("#FFFF5D") }),
      new SolidFill({ color: ColorCSS("#FFCD5C") }),
      new SolidFill({ color: ColorCSS("#FFC8A5") }),
      new SolidFill({ color: ColorCSS("#FF94B8") }),
      new SolidFill({ color: ColorCSS("#DB94C6") }),
      new SolidFill({ color: ColorCSS("#A994C6") }),
      new SolidFill({ color: ColorCSS("#94B0C6") }),
      new SolidFill({ color: ColorCSS("#94E2C6") }),
      new SolidFill({ color: ColorCSS("#94FFB0") }),
      new SolidFill({ color: ColorCSS("#94ffdf") }),
      new SolidFill({ color: ColorCSS("#94eaff") }),
      new SolidFill({ color: ColorCSS("#94c1ff") }),
      new SolidFill({ color: ColorCSS("#a894ff") }),
      new SolidFill({ color: ColorCSS("#ffb194") }),
      new SolidFill({ color: ColorCSS("#90e64e") }),
      new SolidFill({ color: ColorCSS("#e64e4e") }),
      new SolidFill({ color: ColorCSS("#b14ee6") }),
      new SolidFill({ color: ColorCSS("#41c459") }),
      new SolidFill({ color: ColorCSS("#41c4b0") }),
    ];

    const themeAxisFillStyle = new SolidFill({ color: ColorCSS("#ffffff") });
    const themeMajorTickFillStyle = new SolidFill({ color: ColorCSS("#ffffff") });
    const themeMinorTickFillStyle = new SolidFill({ color: ColorCSS("#ffffff") });
    const themeMajorGridlineFillStyle = new SolidFill({ color: ColorCSS("#00000032") });
    const themeMinorGridlineFillStyle = new SolidFill({ color: ColorCSS("#00000014") });
    const themeUiBackgroundFillStyle = new SolidFill({
      color: ColorCSS("rgba(120,120,120,0.5)"),
    }); // Legend color
    const themeUiBackgroundBorderFillStyle = new SolidFill({
      color: ColorCSS("#2b2b2b"),
    });
    const themeCursorGridlineFillStyle = new SolidFill({ color: ColorCSS("#ffffff") });
    // darkMagenta
    const myTheme = customTheme(Themes.cyberSpace, {
      lcjsBackgroundFillStyle: new SolidFill({ color: ColorCSS("#00000000") }),
      panelBackgroundFillStyle: new SolidFill({ color: ColorCSS("#00000000") }),
      seriesBackgroundFillStyle: new SolidFill({ color: ColorCSS("#00000000") }),
      chartTitleFillStyle: themeTextFillStyle,
      axisTitleFillStyle: themeTextFillStyle,
      axisStyle: new SolidLine({ thickness: 1, fillStyle: themeAxisFillStyle }),
      numericTickStrategy: Themes.lightNew.numericTickStrategy
        .setMajorTickStyle((majorTicks) =>
          majorTicks
            .setLabelFillStyle(themeTextFillStyle)
            .setTickStyle(
              new SolidLine({ thickness: 1, fillStyle: themeMajorTickFillStyle })
            )
            .setGridStrokeStyle(
              new SolidLine({ thickness: 1, fillStyle: themeMajorGridlineFillStyle })
            )
        )
        .setMinorTickStyle((minorTicks) =>
          minorTicks
            // @ts-ignore
            .setLabelFillStyle(themeTextFillStyle)
            .setTickStyle(
              new SolidLine({ thickness: 1, fillStyle: themeMinorTickFillStyle })
            )
            .setGridStrokeStyle(
              new SolidLine({ thickness: 1, fillStyle: themeMinorGridlineFillStyle })
            )
        ),
      seriesFillStyle: (i) =>
        themeDataSeriesFillStyles[i % themeDataSeriesFillStyles.length],
      seriesStrokeStyle: (i) =>
        new SolidLine({
          thickness: 2,
          fillStyle: themeDataSeriesFillStyles[i % themeDataSeriesFillStyles.length],
        }),
      uiBackgroundFillStyle: themeUiBackgroundFillStyle,
      uiBackgroundStrokeStyle: new SolidLine({
        thickness: 1,
        fillStyle: themeUiBackgroundBorderFillStyle,
      }),
      uiTextFillStyle: themeTextFillStyle,
      resultTableFillStyle: themeUiBackgroundFillStyle,
      resultTableStrokeStyle: new SolidLine({
        thickness: 1,
        fillStyle: themeUiBackgroundBorderFillStyle,
      }),
      resultTableTextFillStyle: themeTextFillStyle,
      customTickGridStrokeStyle: new SolidLine({
        thickness: 1,
        fillStyle: themeCursorGridlineFillStyle,
      }),
      uiPointableTextBoxFillStyle: themeUiBackgroundFillStyle,
      uiPointableTextBoxStrokeStyle: new SolidLine({
        thickness: 1,
        fillStyle: themeUiBackgroundBorderFillStyle,
      }),
      uiPointableTextBoxTextFillStyle: themeTextFillStyle,
      pointMarkerFillStyle: new SolidFill({ color: ColorCSS("#ffffff") }),
      chartXYZoomingRectangleFillStyle: new SolidFill({ color: ColorCSS("#ffffff16") }),
      chartXYZoomingRectangleStrokeStyle: new SolidLine({
        thickness: 1,
        fillStyle: new SolidFill({ color: ColorCSS("#4f4f4f") }),
      }),
      chartXYFittingRectangleFillStyle: new SolidFill({ color: ColorCSS("#ffffff16") }),
      chartXYFittingRectangleStrokeStyle: new SolidLine({
        thickness: 1,
        fillStyle: new SolidFill({ color: ColorCSS("#4f4f4f") }),
      }),
    });

    const license = `0001-ff5ad4e054f80f2dd0977e5194ea2c7804dcae2d8fc19d352bb53ce081d1e1c8cc21be14a8b691660e1133da1d4d73813c2b4d760ddc385f32cd859fc3f6b7ed664894c62e4fa717fea588bb829429c8f7f9218a4f1f6684ca663999e246-2acb5316c96ce6435be3182ea4ee94db-30450220319f968bebc89be5d0ac8cb5b1903e4e023a07525c1b84d8aed5136bb0667a82022100f3ad7a24bde5f7427f10f3c1367aff4708fad5507d937da7e75220d5e29578d9`;
    if (location.href.includes("tasq.io")) {
      this.chart = lightningChart(license)
        .ChartXY({ container: `${this.chartId}`, theme: myTheme })
        .setTitle("");
    } else {
      this.chart = lightningChart()
        .ChartXY({ container: `${this.chartId}`, theme: myTheme })
        .setTitle("");
    }

    this.chart.setAnimationsEnabled(false);
    this.chart.setMouseInteractionWheelZoom(false);

    this.axisY = this.chart.getDefaultAxisY();
    const timeAxis = this.chart.getDefaultAxisX()

// Add a click event listener to the chart


     // Set the tick strategy of the X-axis to display dates
    // Set the tick strategy of the X-axis to display dates

    timeAxis.setTickStrategy(AxisTickStrategies.DateTime)
    // Add a line series to the chart
    const lineSeries = this.chart.addLineSeries()



    const data = [
    { x: new Date(2023, 2, 13, 12, 0, 0), y: 10 },
    { x: new Date(2023, 2, 14, 12, 0, 0), y: 20 },
    { x: new Date(2023, 2, 15, 12, 0, 0), y: 15 },
    { x: new Date(2023, 2, 16, 12, 0, 0), y: 25 },
    { x: new Date(2023, 2, 17, 12, 0, 0), y: 30 },
  ];

  lineSeries.add(data);
  // const eventMarkers = this.chart.addUIElement(EventMarkers);

  this.chart.onSeriesBackgroundMouseClick((_, event) => {
    console.log(event);
    // const { x, y } = this.chart.engine.clientLocation2Engine(event.clientX, event.clientY);


    const curLocationAxis = translatePoint(
        this.chart.engine.clientLocation2Engine(event.clientX, event.clientY),
        this.chart.engine.scale,
        lineSeries.scale,
    )



    console.log(curLocationAxis.x);

    const buttonReset = this.chart
        .addUIElement(UIElementBuilders.TextBox, { x: timeAxis, y: this.axisY })
        .setText("E")
        .setPosition({
          x: curLocationAxis.x,
          y: curLocationAxis.y
        })
        .setOrigin(UIOrigins.LeftBottom)
        .setMargin({ left: 4, bottom: 4 })

    //       console.log(x);
    //       console.log(y);

    // const eventBar = this.chart.addRectangleSeries()
    //   .setName('E')
    //   .setMouseInteractions(false)
    //   .add({ x:  curLocationAxis.x, y: curLocationAxis.y, width: 10, height: 50 })
    //   .setFillStyle(new SolidFill({ color: ColorHEX("#FF0000") }));


        // Add an event bar at the specified date





  // this.eventBars.push(buttonReset);
});

  }
  beforeDestroy() {
    if (this.chart !== null) {
      this.chart.dispose();
      this.eventBars.forEach((eventBar) => eventBar.dispose());
    }
  }
}
